var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Jumbotron',{attrs:{"title":"Gebührenübersicht"}},[_vm._v(" Zahlungseingänge und offene Gebühren ")]),(_vm.ready)?_c('CRow',[_c('CCol',{attrs:{"md":"8"}},[_c('DataTable',{attrs:{"items":_vm.data.moneyTransactions.items,"fields":[
          {
            key: 'amount',
            label: 'Betrag',
            sorter: false,
            filter: false,
          },
          {
            key: 'transactionDate',
            label: 'Datum',
            sorter: false,
            filter: false,
          },
          {
            key: 'source',
            label: 'Quelle',
            sorter: false,
            filter: false,
          },
          {
            key: 'status',
            label: 'Status',
            sorter: false,
            filter: false,
          } ],"table-filter":{ external: true, lazy: true },"table-filter-value":_vm.tableFilterValue,"sorter":{ external: true, resetable: true },"sorter-value":_vm.sorterValue,"hover":"","loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])]}},{key:"transactionDate",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.transactionDate}})],1)]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('td',[(item.source == 'Stripe')?_c('div',[_vm._v(" "+_vm._s(item.status)+" "),(item.status == 'Failed')?_c('div',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.restartPayment(item.stripePaymentIntentClientSecret)}}},[_vm._v(" Neu starten ")])],1):_vm._e()]):_vm._e(),(item.source != 'Stripe')?_c('div',[_vm._v("Abgeschlossen")]):_vm._e()])]}}],null,false,1835174887)}),(_vm.pages > 1)?_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}):_vm._e()],1),_c('CCol',{attrs:{"md":"4"}},[(_vm.ready)?_c('CCard',[_c('CCardHeader',[_vm._v("Aktuell offener Betrag")]),_c('CCardBody',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.data.amountToPay))),_c('br')])],1):_vm._e(),_c('CCard',[_c('CCardHeader',[_vm._v(" Bezahloptionen ")]),_c('CCardBody',[_c('dl',[_c('dt',[_vm._v("Paypal")]),_c('dd',[_c('a',{attrs:{"target":"_blank","href":"https://paypal.me/simipa?country.x=DE&locale.x=de_DE"}},[_vm._v("paypal.me/simipa")])]),_c('dt',[_vm._v("Überweisung")]),_c('dd',[_vm._v("Empfänger: SiMiPa UG")]),_c('dd',[_vm._v("BIC: HOLVDEB1")]),_c('dd',[_vm._v("IBAN: DE63 1001 7997 5017 2074 68")])])])],1)],1)],1):_vm._e(),(_vm.paymentIntentClientSecret)?_c('CModal',{attrs:{"title":"Bezahlung","show":_vm.showModal,"centered":true,"closeOnBackdrop":true,"size":"lg"},on:{"update:show":_vm.onCancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.executePayment}},[_vm._v("Jetzt bezahlen")])]},proxy:true}],null,false,674872929)},[_c('stripe-element-payment',{ref:"paymentRef",attrs:{"pk":_vm.stripePublicKey,"elements-options":_vm.elementsOptions,"confirm-params":_vm.confirmParams}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }