import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/MoneyTransaction/';

class OwnerMoneyTransactionService {
  refreshPaymentIntent(paymentIntentId: string) {
    return ApiService.post(`${API_URL}RefreshPaymentIntent`, { paymentIntentId });
  }

  startPayment() {
    return ApiService.get(`${API_URL}StartPayment`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new OwnerMoneyTransactionService();
