















































































































import Vue from 'vue';
import OwnerMoneyTransactionService from '@/services/owner/OwnerMoneyTransactionService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import { MetaInfo } from 'vue-meta';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';

class TransactionDto {
  transactionDate!: Date;
}

export default Vue.extend({
  components: { FormatedTimestamp, DataTable, Jumbotron, StripeElementPayment },
  data() {
    return {
      data: {} as {
        amountToPay: number;
        allOwners: { displayName: string; id: string }[];
        moneyTransactions: { items: TransactionDto[] };
      },
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'id', asc: true },
      currentPage: 1,
      loading: false,
      ready: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      paymentIntentClientSecret: '',

      confirmParams: {
        return_url: process.env.VUE_APP_HOST_BASE_URL + '/owner/transactions', // success url
      },
      showModal: false,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
    const paymentIntentId = this.$route.query.payment_intent as string;

    if (paymentIntentId) {
      OwnerMoneyTransactionService.refreshPaymentIntent(paymentIntentId).then(() => {
        this.loadData();
      });
    }
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostMoneyTransactionIndex.meta.title').toString(),
    };
  },
  computed: {
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
    stripeTheme(): string {
      return this.isDarkMode ? 'night' : 'stripe';
    },
    showPaymentButton(): boolean {
      return this.data?.amountToPay > 0.5;
    },
    elementsOptions(): {} {
      const options = {
        appearance: { theme: this.stripeTheme },
        clientSecret: this.paymentIntentClientSecret,
      };
      return options;
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostMoneyTransactionIndex.breadcrumb.last' },
      ]);
    },

    restartPayment(paymentIntentClientSecret: string) {
      this.paymentIntentClientSecret = paymentIntentClientSecret;
      this.showModal = true;
    },

    loadData() {
      this.loading = true;
      OwnerMoneyTransactionService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.ready = true;
        this.loading = false;
        this.data = res.value;
        this.data.moneyTransactions.items = res.value.moneyTransactions.items.map((t: TransactionDto) => ({
          ...t,
          transactionDate: new Date(t.transactionDate),
        }));
        this.data.amountToPay = Number(res.value.amountToPay);
        this.pages = res.value.moneyTransactions.totalPages;
      });
    },

    startPayment() {
      OwnerMoneyTransactionService.startPayment().then((res) => {
        this.paymentIntentClientSecret = res.value.paymentIntentClientSecret;
        this.stripePublicKey = res.value.stripePublicKey;
        this.showModal = true;
      });
    },

    executePayment() {
      (this.$refs.paymentRef as any).submit();
    },

    onCancel() {
      this.showModal = false;
    },
  },
});
